import React from 'react'
import SEO from '../components/SEO'

export default function Ayuda() {
  return (
    <>
      <SEO title="Ayuda" />
      <main className="flex">
        <p className="w-11/12 mx-auto mt-10 md:w-1/2 lg:w-1/3 border-pxblue-600 border p-4 bg-gray-100 rounded-md text-pxtx-200">
          Si tienes alguna duda o necesitas ayuda, comunícate con{' '}
          <span className="font-bold">Karen Torres</span> a la{' '}
          <span className="font-bold">extensión 204</span> o al correo:{' '}
          <a
            href="mailto:ktorres@paquetexpress.com.mx"
            className="font-medium text-pxblue underline"
          >
            ktorres@paquetexpress.com.mx
          </a>
          .
        </p>
      </main>
    </>
  )
}
